:root {
    --color-black: #000000;
    --color-grey: #8396A6;
    --color-white: #FFFFFF;
    --color-off-white: #FFFCF7;
    --color-rose: #FFEDE0;
    --color-cream: #EADED4;
    --color-light-blue: #E3EEF7;
    --color-dark-blue: #282E53;
    --color-neon-blue: #3859FF;
    --color-yellow: #EFFEB4;


    --font-primary: 'PPNikkeiMaru', sans-serif;
    --font-secondary: 'PPEditorialNew', serif;
    --font-body: 'PT Sans', sans-serif;
}
