.about-section {
  display: flex;
  justify-content: space-between;
  gap: 64px;
  align-items: center;
  padding: 80px 64px;
}

.text-content {
 display: flex;
 flex-direction: column;
 gap: 24px;
}

.small-section {
    padding: 0px 64px;
    margin-bottom: 80px;
}

.location-section {
    display: flex;
    flex-direction: row;
    gap: 64px;
    align-items: center;
    background-color: var(--color-cream);
    padding: 0px 0px 0px 64px;
}

.big-description {
  font-size: 24px;
}

.image {
  max-width: 600px;
  width: 100%;
  height: auto;
} 

.big-6 {
  width: 80%;
  order: 1;  
}

.map-iframe {
    width: 100%;
    height: 500px;
}

@media (max-width: 1200px) {
  .about-section {
    flex-direction: column;
  }

}

@media (max-width: 700px) {
.about-section {
    padding: 40px 24px;
    gap: 40px;
}

.location-section {
    flex-direction: column;
    padding: 40px 24px 124px 24px; 
    gap: 40px;
}

.small-section {
    padding: 0px 24px;
}

.map-iframe {
    height: 300px;
}

}

