.layout-container {
  display: flex;
  flex-direction: column;
  margin: 0;
}

main {
  min-height: 100vh;
}

.navbar {
  color: var(--color-black);
  padding: 24px 40px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.navbar-left,
.navbar-right,
.navbar-left-links {
  display: flex;
  gap: 40px;
  align-items: center;
}

.menu-item {
  text-decoration: none;
  color: inherit;
}

.logo-text {
  display: block;
  height: 16px;
}

.logo-icon {
  display: none;
  height: 20px;
}

.footer {
  color: var(--color-black);
  padding: 24px 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  gap: 24px;
}

.footer-link {
    display:flex;
    align-items: center;
    gap: 8px;
}

.footer-links-left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
}

.footer-links-right {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
}

.inverted {
  filter: invert(1);
}

.home {
  color: var(--color-white) !important;
}

.hidden {
  transform: translateY(-100%);
  transition: transform 0.2s ease-in-out;
}

.side-menu, 
.menu-icon {
    display: none;
}

@media (max-width: 768px) {
  .navbar {
    padding: 24px;
    justify-content: space-between;
  }

  .menu-item {
    width: 80px;
  }

  .navbar-left-links {
    display: none;
  }

  .navbar-right {
    gap: 24px;
  }

  .logo-text {
    display: none;
  }

  .logo-icon {
    display: block;
  }

  .logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid #000000;
    width: 32px;
    height: 32px;
  }



  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .side-menu {
    display: flex;
    flex-direction: column;
    text-align: right;
    gap: 24px;
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    max-width: 200px;
    height: 100%;
    background-color: #fffcf7;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 9;
    padding: 24px;
    box-sizing: border-box;
  }

  .side-menu.open {
    transform: translateX(0);
  }

  .close-icon {
    display: flex;
    cursor: pointer;
    justify-content: flex-end;

  }
}
