.homepage-container {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-image: url('https://res.cloudinary.com/dxznerft3/image/upload/v1732558036/homepage_pictures/background.jpg');
  background-size: cover;
  background-position: center;
  gap: 96px;
  color: var(--color-white);
}

.text-logo {
  width: 30vw; /* 30% of the viewport width for desktops */
}

@media (max-width: 768px) {
  .text-logo {
    width: 70vw; /* 70% of the viewport width for phones */
  }
}

.countdown {
  font-size: 40px;
  font-family: var(--font-secondary);
}
