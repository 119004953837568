.trainer-recruiting-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    padding: 24px 64px;
  }

  .left-container {
    flex: 1;
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.right-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Add any additional styling needed */
}

.border-box {
    border: 2px solid var(--color-black);
    padding: 8px 16px;
    max-width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.border-img {
    border: 2px solid var(--color-black);
    padding: 8px;
    max-width: 100%;
}

  .content-section {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 780px) {
    .trainer-recruiting-container {
      flex-direction: column;  
      padding: 40px 24px 60px 24px;
      gap: 40px;
    }

    .left-container {
        width: 100%;
    }

    .right-container {
        width: 100%;
    }
    
    .border-box {
        max-width: 100%;
    }
  }



  .form-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
    gap: 48px;
}

@media (max-width: 768px) {
    .form-container {
        width: 100%;
    }
}

.form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
}

.form-input {
    width: 100%;
    font-size: 20px;
    border: none;
    border-bottom: 2px solid black;
    background-color: transparent;
    color: var(--color-black);
    padding: 8px 0;
    position: relative;
}

.form-input::placeholder {
    color: var(--color-grey);
}

.form-input:focus::placeholder {
    color: transparent;
}

.form-input:focus {
    outline: none;
}

.typing-indicator {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 2px;
    background-color: var(--color-black);
    animation: blink 1s step-start infinite;
}

@keyframes blink {
    50% {
        opacity: 0;
    }
}