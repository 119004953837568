.wod-container {
  padding: 2rem 20rem;
  font-family: 'PPNikkeiMaru', sans-serif;
  background-color: var(--color-light-blue);
  min-height: 100vh;
}

.wod-title {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 0.5rem;
  color: var(--color-black);
}

.wod-date {
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-dark-blue);
}

.wod-section {
  margin-bottom: 3rem;
}

.section-title {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: var(--color-black);
  padding-bottom: 0.5rem;
}

.exercise-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

.exercise-card {
  display: flex;
  flex-direction: column;
  border: 2px solid var(--color-black);
  overflow: hidden;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.exercise-name {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 1rem;
  background-color: var(--color-black);
  color: var(--color-white);
  text-align: center;
}

.exercise-reps {
  font-size: 1rem;
  padding: 0.5rem;
  text-align: center;
  color: var(--color-black);
  border-bottom: 2px solid var(--color-black);
}

.exercise-video-container {
  position: relative;
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  overflow: hidden;
}

.exercise-video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: var(--color-white);
}

.challenge-section .exercise-card {
  border-color: var(--color-neon-blue);
}

.challenge-section .exercise-name {
  background-color: var(--color-neon-blue);
  color: white;
}

.loading, .error, .no-workout {
  text-align: center;
  padding: 3rem;
  font-size: 1.5rem;
  color: var(--color-grey);
}

.error {
  color: #e74c3c;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .wod-container {
    padding: 1rem;
  }
  
  .wod-title {
    font-size: 2rem;
  }
  
  .wod-date {
    font-size: 1.2rem;
  }
  
  .section-title {
    font-size: 1.5rem;
  }
  
  .exercise-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }
} 