@import './variables.css';

@font-face {
  font-family: 'PPNikkeiMaru';
  src: url('../public/fonts/PPNikkeiMaru-Regular.woff2') format('woff2'),
       url('../public/fonts/PPNikkeiMaru-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PPEditorialNew';
  src: url('../public/fonts/PPEditorialNew-Regular.woff2') format('woff2'),
       url('../public/fonts/PPEditorialNew-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


body {
  font-family: 'PT Sans', sans-serif;
  color: var(--color-black);
  background-color: var(--color-off-white);
  margin: 0;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: inherit;
}

.text-link {
  text-decoration: underline;
}

p {
  margin: 0;
}

h1, h2, h3 {
  font-family: var(--font-primary);
  margin: 0;
  font-weight: normal;
}

h4,h5,h6 {
  margin: 0;
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 24px;
}

h4 {
  font-size: 16px;
}

input {
  font-family: var(--font-body);
}

button {
  padding: 16px 24px 16px 24px;
  background-color: var(--color-black);
  color: var(--color-white);
  gap: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  font-family: var(--font-body);
  border: none;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.special {
  background-color: var(--color-neon-blue) !important;
}

.error-text {
    color: red;
}
.blue-background {
  background-color: var(--color-light-blue);
}

.default-container {
  padding: 40px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

 
.workout-card {
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding: 24px;
  border: 2px solid var(--color-black);
  width: fit-content; /* Ensures the width is the smallest that fits the content */
}

.workout-card-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.workout-card-right {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 8px;
}

.profile-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 2px solid var(--color-black);
}

.flex-vertical {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.centered {
  align-items: center !important;
}

.gap-xxs {
  gap: 8px;
}

.gap-xs {
  gap: 12px;
}

.gap-s {
  gap: 24px;
}

.gap-m {
  gap: 48px;
}

.gap-l {
  gap: 64px;
}

.gap-xl {
  gap: 80px;
}


@media (max-width: 768px) {
  .default-container {
      padding: 40px 24px 160px 24px;
  }

}